import React, { useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
import SessionTimeoutDialog from "../SessionTimeoutDialog";
import { useAuth } from '../../hooks/auth';

var countdownInterval;
var timeout;

const SessionTimeout = ({ save = null, answers, moduleID, statusNumber }) => {
  const [timeoutModalShow, setTimeoutModalShow] = useState(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);
  const { signOut, user, userRoles } = useAuth();
  const [userObject, setUserObject] = useState(typeof (user) == "string" ? JSON.parse(user) : user);
  const idleTimer = useRef(null);

  const clearSessionTimeout = () => {
    clearTimeout(timeout);
  };

  const clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  const handleLogout = (isTimedOut = false) => {
    setTimeoutModalShow(false);
    clearSessionInterval();
    clearSessionTimeout();
    signOut();
  };

  const handleContinue = () => {
    setTimeoutModalShow(false);
    clearSessionInterval();
    clearSessionTimeout();
  };

  const onActive = () => {
    if (!timeoutModalShow) {
      clearSessionInterval();
      clearSessionTimeout();
    }
  };

  const onIdle = () => { //Logout user after 60 minutes inactive
    // const delay = 500; //To test (5 second)
    const delay = 1000 * 60 * 55; //55 minutes
    if (user && !timeoutModalShow) {
      timeout = setTimeout(() => {
        // let countDown = 5; //To test (5 seconds)
        let countDown = 300; //5 minutes
        setTimeoutModalShow(true);
        setTimeoutCountdown(countDown);
        countdownInterval = setInterval(async() => {
          if (countDown > 0) {
            setTimeoutCountdown(--countDown);
          } else {
            if(save != null){
              if ((userRoles.includes("RoleAdmin") && statusNumber == 8) //Reactivated By Admin
                    || (userRoles.includes("RoleInfoHolder") && (statusNumber == null || statusNumber == 1 || statusNumber == 3 || statusNumber == 5 || statusNumber == 6 || statusNumber == 8)) //Not Saved As Draft OR Save as Draft OR Reopened By Local OR Reopened By Global OR Version Approved OR Reactivated By Admin
                    || (userRoles.includes("RoleApprover") && userObject.companyID == null && statusNumber == 5)
                    || (userRoles.includes("RoleESGReviewer") && userObject.companyID == null && statusNumber == 12) 
                    || (userRoles.includes("RoleESGApprover") && userObject.companyID == null && statusNumber == 14) 
                    || (userRoles.includes("RoleESGManager") && userObject.companyID == null && statusNumber == 10)) {
                      clearSessionInterval();

                      if(userRoles.includes("RoleAdmin") && statusNumber == 8)
                        await save(answers, moduleID, true);
                      else
                        await save(answers, moduleID, false);
              }
            }
              
            handleLogout(true);
          }
        }, 1000);
      }, delay);
    }
  };

  return (
    <>
      <IdleTimer
        ref={idleTimer}
        onActive={onActive}
        onIdle={onIdle}
        debounce={250}
        timeout={5000}
      />
      <SessionTimeoutDialog
        countdown={timeoutCountdown}
        onContinue={handleContinue}
        show={timeoutModalShow}
      />
    </>
  );
}

export default SessionTimeout;