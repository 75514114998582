import React from 'react';
import TableCrud from '../../components/TableCrud';

const ESGSubjectIndex = () => {
    const columns = [
        {
            label: "Name",
            name: "name"
        }
    ]

    return (
        <TableCrud title={"ESG Subject"} table={"esgSubject"} columns={columns}/>
    );
}

export default ESGSubjectIndex;
