import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';
import api, { exceptionNotificationAPI } from '../../services/api';

import getValidationErrors from '../../utils/getValidationErrors';

import { Container } from './styles';

const SignIn = () => {
    const { signIn, user, ssoSignIn, ssoRequestToken } = useAuth();
    const history = useHistory();
    const [username, setUsername] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [password, setPassword] = useState('');
    const [userRoles, setUserRoles] = useState([]);
    const [ssoToken, setSSOToken] = useState('');
    const [forgotPasswordClicked, setforgotPasswordClicked] = useState('');
    const [loginStep, setLoginStep] = useState('');


    useEffect(() => {
        if (user) {
            // history.push("/");
            history.push("/signin/MultiFactorAuthentication");
        }
    }, [history, user]);

    const handleSubmit = async () => {
        try {
            const schema = Yup.object().shape({
                username: Yup.string()
                    .required('Username is required'),
                password: Yup.string().required('Password is required')
            });

            await schema.validate({ username, password }, {
                abortEarly: false,
            });

            var result = await signIn({
                username: username,
                password: password
            });

            if (result.data.response)
                history.push("/signin/MultiFactorAuthentication");
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                getValidationErrors(error);

                return;
            }

            exceptionNotificationAPI(error);
        }
    };

    const onEnterPress = (e) => {
        if (e.key == 'Enter') {
            handleSubmit();
        }
    }

    const handleSSOSignIn = async (signInUsername = null, token = null) => {
        signInUsername = signInUsername || username
        token = token || ssoToken
        var ssoResult = await ssoSignIn(token, signInUsername);
        if (ssoResult.data.response) {
            history.push("/");
        }
    }

    const SSORoleSelection = async () => {
        try {
            var response = await ssoRequestToken();
            var token = response.token;
            setSSOToken(token);
            var uniqueName = response.uniqueName;

            if (token) {
                const result = await api.post('/getAllUserRoles', {
                    'userName': uniqueName
                });

                if (result.data.response) {
                    if (result.data.response.length > 1) {
                        setUserRoles([...result.data.response]);
                        setLoginStep("roleSelection");
                        // document.querySelector("#usernameCard").classList.add("hidden")
                        // document.querySelector("#roleSelectionCard").classList.remove("hidden")
                    }
                    else {
                        var username = result.data.response[0].userName;
                        setSelectedRole(result.data.response[0].role);
                        setUsername(username);
                        // document.querySelector("#usernameCard").classList.add("hidden")
                        await handleSSOSignIn(username, token);
                    }
                }
            }
        }
        catch (error) {
            exceptionNotificationAPI(error);
        }
    }

    const continueToRoleSelection = async () => {
        try {


            const result = await api.post('/getAllUserRoles', {
                'username': userEmail
            });

            if (result.data.response) {
                if (result.data.response.length > 1) {
                    setUserRoles([...result.data.response]);
                    setLoginStep("roleSelection");
                    // document.querySelector("#usernameCard").classList.add("hidden")
                    // document.querySelector("#roleSelectionCard").classList.remove("hidden")
                }
                else {
                    setUserRoles([...result.data.response]);
                    setSelectedRole(result.data.response[0].role);
                    setUsername(result.data.response[0].userName);
                    // document.querySelector("#usernameCard").classList.add("hidden")
                    continueToPassword();
                }
            }
        }
        catch (error) {
            exceptionNotificationAPI(error);
        }
    }

    const returnToUsername = async () => {
        setLoginStep("");
        // document.querySelector("#roleSelectionCard").classList.add("hidden")
        // document.querySelector("#usernameCard").classList.remove("hidden")
    }

    const continueToPassword = async () => {
        setLoginStep("password");
        // document.querySelector("#roleSelectionCard").classList.add("hidden")
        // document.querySelector("#passwordCard").classList.remove("hidden")
    }

    const returnToRoleSelection = async () => {
        if (userRoles.length > 1) {
            setLoginStep("roleSelection");
            // document.querySelector("#passwordCard").classList.add("hidden")
            // document.querySelector("#roleSelectionCard").classList.remove("hidden")
        }
        else {
            setLoginStep("");
            // document.querySelector("#passwordCard").classList.add("hidden")
            // document.querySelector("#usernameCard").classList.remove("hidden")
        }
    }

    const selectRole = async (e) => {
        setUsername(e.target.value);
        setSelectedRole(e.target.value);
    }

    const onSelectRoleClick = () => {
        if (ssoToken)
            handleSSOSignIn();
        else
            continueToPassword()
    }

    const onForgotPasswordClicked = async () => {
        if (!forgotPasswordClicked) {
            try {
                setforgotPasswordClicked(true);
                setLoginStep("forgotPassword");
                const result = await api.post('User/forgotPassword', {
                    'username': username
                });

                if (result.data.response) {
                    //"Email sent to " + userEmail + ". Please follow the password reset process in the e-mail."
                }
            }
            catch (error) {
                exceptionNotificationAPI(error);
            }
        }
    }

    return (
        <Container>
            <div className="main-div container" align="center">
                <div className="card">
                    {loginStep === "" ? <div id="usernameCard" className="card-body">
                        <div className="form-group">
                            <label>
                                E-mail
                            </label>
                            <input className="form-control" id="UserName" name="userName" placeholder="" onChange={(e) => setUserEmail(e.target.value)} required={true} type="text" onKeyPressCapture={(e) => onEnterPress(e)} />
                        </div>
                        {/* <button className="btn" onClick={continueToRoleSelection}>Login to 3rd Party Leaf Suppliers</button> */}
                        <button className="btn" onClick={continueToRoleSelection}>Login</button>
                        { <button className="btn" onClick={SSORoleSelection}>Login to BAT Leaf Operations</button> }
                    </div>
                        : loginStep === "roleSelection" ?
                            <div className="card-body">
                                <div className="form-group">
                                    <label>
                                        Select your role for this session
                                    </label>
                                    <select className="form-control" onChange={(e) => selectRole(e)} value={selectedRole ? selectedRole : ""}>
                                        <option key={0} value={""}>Select a role</option>
                                        {

                                            userRoles ?
                                                userRoles.map(userRole => {
                                                    return <option key={userRole.userName} value={userRole.userName}>{userRole.roleDisplayName + (userRole.company != null ? (" - " + userRole.company) : "") + (userRole.esgSubjectName != null ? (" - " + userRole.esgSubjectName) : "") + (userRole.groupName != null ? (" - " + userRole.groupName) : "")}</option>
                                                })
                                                :
                                                <></>
                                        }
                                    </select>
                                </div>

                                <button className="btn" onClick={onSelectRoleClick}>Continue</button>
                                <button className="btn return-button" onClick={returnToUsername}>Return</button>
                            </div>
                            : loginStep === "password" ?
                                <div className="card-body">
                                    <div className="form-group">
                                        <label>
                                            Password
                                        </label>
                                        <input className="form-control" id="Password" name="password" placeholder="" onChange={(e) => setPassword(e.target.value)} required={true} type="password" onKeyPressCapture={(e) => onEnterPress(e)} />
                                    </div>
                                    <button className="btn" onClick={handleSubmit}>Login</button>
                                    <button className="btn return-button" onClick={returnToRoleSelection}>Return</button>
                                    <a onClick={onForgotPasswordClicked}>Forgot Password?</a>
                                </div>
                                : loginStep === "forgotPassword" ?
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label>
                                                {"Email sent to " + userEmail + ". Please follow the password reset process in the e-mail."}
                                            </label>
                                        </div>
                                    </div>
                                    : <></>}
                </div>
                <div className="card card-terms mb-2">
                    <div className="terms">
                        <p>Third parties' representatives accessing this tool can learn more about how BAT is processing personal data for business relationships from <a href="https://www.bat.com/group/sites/UK__9D9KCY.nsf/vwPagesWebLive/DOBB5H9Z" target="_blank" rel="noreferrer">here</a></p>
                        <p>BAT employees accessing this tool can learn more about how their personal data is processed by checking the local BAT Employees Privacy Notice where applicable. In case of queries, please contact your local LEX team.</p>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default SignIn;
