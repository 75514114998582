import React from 'react';
import FormCrud from '../../components/FormCrud';

const ESGSubjectForm = () => {
    const fields = [
        {
            label: "Name",
            name: "name",
            type: "input"
        }
    ]

    return (
        <FormCrud formName={"esgSubject"} title={"ESG Subject"} fields={fields}/>
    );
}

export default ESGSubjectForm;
