import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useParams, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { Container } from './styles';
import Select, { ActionMeta, OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';

import Api, { exceptionNotificationAPI } from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

import Button from '../Button';
import FormInput from '../FormInput';
import CancelButton from '../CancelButton';

const FormCrud = ({ fields, title, formName, isLoading, setSelectedReportYearID = null, multiple = false }) => {
    const history = useHistory();
    const formRef = useRef(null);
    const { id } = useParams();
    const [data, setData] = useState(null);

    const [multipleValues, setMultipleValues] = useState([]);
    const [selectCustomValue, setSelectCustomValue] = useState([]);
    const [selectCustomInput, setSelectCustomInput] = useState("");
    const [disabledCustomSelect, setDisabledCustomSelect] = useState(true);
    const [companies, setCompanies] = useState(null);
    const [esgSubjectOptions, setESGSubjectOptions] = useState(null);
    const [esgCompanies, setEsgCompanies] = useState([]);
    const animatedComponents = makeAnimated();

    useEffect(() => {
        loadCompanies();
        loadESGSubjects();
    }, []);

    useEffect(() => {
        if (data === null && id != null && !isLoading) {

            loadData();
        }
    }, [isLoading]);

    useEffect(() => {
        console.log("value", selectCustomValue)
        console.log("input", selectCustomInput)
    }, [selectCustomValue, selectCustomInput]);

    const loadCompanies = async () => {
        await Api.get('Company/getAll').then((result) => {
            if (result && result.data) {
                var companyOptions = result.data.response.map(item => ({ value: item.companyID, label: item.name }));

                companyOptions = [{ value: "", label: "Global" }, ...companyOptions];

                var esgCompaniesOptions = result.data.response.filter(c => c.isESGYear).map(item => ({ value: item.companyID, label: item.name }));

                setCompanies(companyOptions);
                setEsgCompanies(esgCompaniesOptions);

                console.log(result.data.response);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadESGSubjects = async () => {
        await Api.get('ESGSubject/getAll').then((result) => {
            if (result && result.data) {
                var esgOptions = result.data.response.map(item => ({ value: item.esgSubjectID, text: item.name }));
               
                var newESGOptions = [{value: "", text: "Select an option"}, ...esgOptions];

                setESGSubjectOptions(newESGOptions);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }


    const getObjects = (currentObject, fragments) => {
        if (fragments.length > 0) {
            var object = currentObject[fragments[0]];

            if (object != null) {
                fragments.shift();
                return getObjects(object, fragments);
            }
            else {
                return "";
            }
        }

        return currentObject;
    }

    const loadData = async () => {
        await Api.get(`${formName}/get?id=${id}`).then((result) => {
            if (result && result.data) {
                var dataObj = {};
                var companyOptions = [];
                var esgOptions = [];

                fields.forEach(f => {
                    if (f.name == "sectionCompany" || f.name == "kpiCompany" || f.name == "columnRowCompany" || f.name == "reportYearCompany" || f.name == "yearCompany") {

                        var dataMultiple = result.data.response[f.name]?.map((sc) => {
                            var data = {
                                value: sc.companyID,
                                label: sc.company.name
                            };
                            return data;
                        });
                        setMultipleValues(dataMultiple);
                    } else if (f.name == "customOptions") {
                        if (result.data.response["customOptions"] != null) {
                            var exist = result.data.response["customOptions"].indexOf(";") > -1;
                            if (exist) {
                                var dataMultiple = result.data.response["customOptions"].split(";");
                                if (formName === "group") {
                                    var newDataMulti = dataMultiple.map((e) => {
                                        var companyName = result.data.response["companies"].find(x => x.companyID === e);

                                        var newData = {
                                            "value": e,
                                            "label": companyName.name
                                        }
                                        return newData;
                                    });
                                } else {
                                    var newDataMulti = dataMultiple.map((e) => {
                                        var newData = {
                                            "value": e,
                                            "label": e
                                        }
                                        return newData;
                                    });
                                }
                                if (result.data.response["unitMeasureID"] == 'BFB810A1-758C-4623-BE44-82CA1D94F583')
                                    setDisabledCustomSelect(false);
                                else
                                    setDisabledCustomSelect(true);

                                setSelectCustomValue(newDataMulti);
                            } else {
                                if (result.data.response["unitMeasureID"] == 'BFB810A1-758C-4623-BE44-82CA1D94F583')
                                    setDisabledCustomSelect(false);
                                else
                                    setDisabledCustomSelect(true);

                                setSelectCustomValue([]);
                            }
                        } else {
                            if (result.data.response["unitMeasureID"] == 'BFB810A1-758C-4623-BE44-82CA1D94F583')
                                setDisabledCustomSelect(false);
                            else
                                setDisabledCustomSelect(true);

                            setSelectCustomValue([]);
                        }
                    } else {
                        if (f.name.indexOf(".") > -1) {
                            var fragments = f.name.split('.');

                            var object = getObjects(result.data.response, fragments);

                            dataObj[f.name] = (object != null ? object : "");
                        }
                        else if(f.name == "reportYearID") {
                            if(setSelectedReportYearID != null) {
                                setSelectedReportYearID(result.data.response[f.name]);
                            }
                            dataObj[f.name] = result.data.response[f.name];
                        }
                        else {
                            dataObj[f.name] = result.data.response[f.name];

                            if (formName == "user") {
                                if (f.name == "companyID") {
                                    dataObj[f.name] = dataObj[f.name] !== null ? dataObj[f.name] : "";

                                    if (dataObj["roleID"] == "BA6C43EB-7334-463B-B5CB-D712BD29339D") { //InfoHolder
                                        companyOptions = f.options;
                                    }
                                    else {
                                        companyOptions = f.options;
                                    }
                                }
                                if(f.name == "esgSubjectID") {
                                    dataObj[f.name] = dataObj[f.name] !== null ? dataObj[f.name] : "";                            
                                    esgOptions = f.options;
                                    
                                }

                            }
                        }
                    }
                });

                formRef.current.setData(dataObj);
                setData(dataObj);

                //When access CRUD form to Edit an existent KPI
                if (formName === "user") {
                    document.getElementById('companyID').disabled = document.getElementById('roleID').value === "23D9D409-D7AA-4966-9047-48C04B41F0A1" /*Admin*/ ? true : false;
                    document.getElementById('groupID').disabled = document.getElementById('roleID').value === "23D9D409-D7AA-4966-9047-48C04B41F0A1" /*Admin*/ ? true : false;
                    document.getElementById('esgSubjectID').disabled = document.getElementById('esgSubjectID').value === "05A51FF9-0663-4265-900D-060903D26C94" /*ESG Reviewer*/ ? true : false;
                    document.getElementById('isActive').checked = dataObj['isActive'];


                    if (document.getElementById('roleID').value === "BA6C43EB-7334-463B-B5CB-D712BD29339D") { //InfoHolder
                        //document.getElementById('companyID').length = 0;

                        companyOptions.map((item) => {
                            document.getElementById('companyID').append(new Option(item.text, item.value));
                        });
                        esgOptions.map((item) => {
                            document.getElementById('esgSubjectID').append(new Option(item.text, item.value));
                        });

                        document.getElementById('companyID').value = dataObj['companyID'];
                        document.getElementById('groupID').value = dataObj['groupID'];
                        document.getElementById('isActive').value = dataObj['isActive'];
                        document.getElementById('esgSubjectID').value = dataObj['esgSubjectID'];


                    }
                    else if(document.getElementById('roleID').value === "05A51FF9-0663-4265-900D-060903D26C94"){
                        // esgOptions.map((item) => {
                        //     document.getElementById('esgSubjectID').append(new Option(item.text, item.value));
                        // });

                        document.getElementById('companyID').value = dataObj['companyID'];
                        document.getElementById('groupID').value = dataObj['groupID'];
                        document.getElementById('isActive').value = dataObj['isActive'];
                        document.getElementById('esgSubjectID').value = dataObj['esgSubjectID'];
                    }
                }
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }



    const handleSubmit = async (data) => {
        try {
            var obj = {}
            var postObj = {};
            const submitUrl = `${formName}/${(id != null) ? "edit" : "new"}`;

            if (formName === "kpi")
                postObj[formName + "id"] = id;
            else {
                if (formName === "user") {
                    postObj["Id"] = id;
                    data['needReadConsent'] = document.getElementById('needReadConsent')?.checked;
                    data['isActive'] = document.getElementById('isActive')?.checked;

                }
                else
                    postObj[formName + "ID"] = id;
            }

            console.log(fields);

            fields.map(f => {
                if (f.required !== undefined && f.required === false) {
                    if (f.name === "calculation") {
                        if (data["inputCalc"] === "Calculation") {
                            var calculationFields = ["firstValue", "operation", "secondValue"];

                            calculationFields.map(item => {
                                return obj[item] = Yup.string().required(`${f.label} is required`);
                            });
                        }

                        return null;
                    } else {
                        return null;
                    }
                }
                else {
                    if (f.editable !== undefined && f.editable === false) {
                        return null;
                    } else {
                        return obj[f.name] = Yup.string().required(`${f.label} is required`);
                    }
                }
            })

            console.log(obj);
            console.log("data", data);

            const schema = Yup.object().shape(obj);

            await schema.validate(data, {
                abortEarly: false,
            });

            fields.map(f => {
                return postObj[f.name] = data[f.name]
            })

            // FIXO, CUIDAR (CUSTOM OPTIONS)
            if (selectCustomValue.length > 0) {
                postObj.customOptions = selectCustomValue.map((e) => { return e.value }).join(';');
            }

            var selector = formName + "Company"
            // FIXO, CUIDAR (SECTION COMPANY)
            if (multipleValues.length > 0) {
                postObj[selector] = multipleValues.map((m) => {
                    var data = {
                        companyID: m.value ? m.value : 0
                    }
                    return data;
                });
            }


            await Api.post(submitUrl, postObj);

            if (formName === "unitMeasure") {
                history.push(`/answerTypeKPI`);
            } else {
                history.push(`/${formName}`);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error);

                formRef.current.setErrors(errors);

                return;
            }

            exceptionNotificationAPI(error);
        }
    }

    return (
        <Container className="container">
            <div>
                <h3>{(id != null) ? "Edit" : "New"} {title}</h3>
                <Form onSubmit={handleSubmit} ref={formRef}>
                    <table className="table table-striped">
                        <tbody>
                            {
                                fields.map((f, i) => {
                                    if (f.type === "selectcustom" && formName != "Group") {
                                        return <>
                                            <tr key={i + "Tr"} id={f.name + "Tr"}>
                                                <td>
                                                    <label>
                                                        {`${f.label}`}  {f.required === undefined || f.required === true ? <span className="required">*</span> : <></>}
                                                    </label>
                                                </td>
                                                <td>
                                                    {formName == "group" ?

                                                        <Select
                                                            name="customOptions"
                                                            id="customOptions"
                                                            isClearable={true}
                                                            placeholder="Select one or more companies..."
                                                            onChange={(e) => { setSelectCustomValue(e); }}
                                                            value={selectCustomValue}
                                                            inputValue={selectCustomInput}
                                                            closeMenuOnSelect={false}
                                                            components={animatedComponents}
                                                            isMulti
                                                            options={companies}
                                                        />
                                                        :
                                                        <CreatableSelect
                                                            name="customOptions"
                                                            id={`customOptions_${f.name}`}
                                                            // isDisabled={disabledCustomSelect}
                                                            components={{ DropdownIndicator: null }}
                                                            isClearable={true}
                                                            isMulti
                                                            menuIsOpen={false}
                                                            placeholder="Type something and press enter..."
                                                            onChange={(e) => { setSelectCustomValue(e); }}
                                                            onInputChange={(e) => setSelectCustomInput(e)}
                                                            onKeyDown={(e) => {
                                                                switch (e.key) {
                                                                    case 'Enter':
                                                                    case 'Tab':
                                                                        if (selectCustomInput == "")
                                                                            return;
                                                                        var newData = {
                                                                            "value": selectCustomInput,
                                                                            "label": selectCustomInput
                                                                        }
                                                                        var newArray = [...selectCustomValue, newData];
                                                                        setSelectCustomInput("");
                                                                        setSelectCustomValue(newArray);
                                                                        e.preventDefault();
                                                                }
                                                            }}
                                                            value={selectCustomValue}
                                                            inputValue={selectCustomInput}
                                                        />
                                                    }
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    else if (f.type === "select") {
                                        if (f.name === "calculation") {
                                            return <>
                                                <tr key={i + "Tr"} id={f.name + "Tr"}>
                                                    <td>
                                                        <label>
                                                            {`${f.label}`}  {f.required === undefined || f.required === true ? <span className="required">*</span> : <></>}
                                                        </label>
                                                    </td>
                                                    <td style={{ display: "flex" }}>
                                                        <div style={{ width: "45%" }}>
                                                            <FormInput
                                                                name="firstValue"
                                                                id="firstValue"
                                                                inputType={f.type}
                                                                placeholder={{ value: "", text: "" }}
                                                                options={f.options}
                                                                onChange={() => (f.onChanged != null) ? f.onChanged(formRef.current) : null}
                                                                hideAlert={true} />
                                                        </div>
                                                        <div style={{ width: "10%" }}>
                                                            <FormInput
                                                                name="operation"
                                                                id="operation"
                                                                placeholder={{ value: "", text: "" }}
                                                                options={[
                                                                    { value: "+", text: "+" },
                                                                    { value: "-", text: "-" },
                                                                    { value: "/", text: "/" },
                                                                    { value: "*", text: "*" },
                                                                    { value: "Average", text: "Average" },
                                                                ]}
                                                                inputType={"select"}
                                                                onChange={() => (f.onChanged != null) ? f.onChanged(formRef.current) : null}
                                                                hideAlert={true} />
                                                        </div>
                                                        <div style={{ width: "45%" }}>
                                                            <FormInput
                                                                name="secondValue"
                                                                id="secondValue"
                                                                inputType={f.type}
                                                                placeholder={{ value: "", text: "" }}
                                                                onChange={() => (f.onChanged != null) ? f.onChanged(formRef.current) : null}
                                                                options={f.options} /></div>
                                                    </td>
                                                </tr>
                                                <tr key={i + "CalculationTr"}>
                                                    <td>Calculation (Formula)</td>
                                                    <td>
                                                        <FormInput
                                                            disabled
                                                            name={f.name}
                                                            id={f.name}
                                                            inputType="input"
                                                            placeholder={f.placeholder}
                                                            options={f.options} />
                                                    </td>
                                                </tr>
                                            </>
                                        } else {
                                            return <tr key={i + "Tr"} id={f.name + "Tr"}>
                                                <td width="25%">
                                                    <label>
                                                        {`${f.label}`}  {f.required === undefined || f.required === true ? <span className="required">*</span> : <></>}
                                                    </label>
                                                </td>
                                                <td width="75%">
                                                    {!f.multiple ?
                                                        <FormInput
                                                            name={f.name}
                                                            id={f.name}
                                                            checked={f.checked}
                                                            inputType={f.type}
                                                            placeholder={f.placeholder}
                                                            options={f.options}
                                                            disabled={f.disabled ? f.disabled : false}
                                                            onChange={(a) => {
                                                                if (f.name == "unitMeasureID" && a.target.value != 24) {
                                                                    setDisabledCustomSelect(true);
                                                                    setSelectCustomValue([]);
                                                                } else if (f.name == "unitMeasureID" && a.target.value == 24) {
                                                                    setDisabledCustomSelect(false);
                                                                    setSelectCustomValue([]);
                                                                } else {
                                                                    setDisabledCustomSelect(true);
                                                                }

                                                                if (f.onChanged != null) {
                                                                    f.onChanged(formRef.current)
                                                                }
                                                                return;
                                                            }} />
                                                        :
                                                        <Select
                                                            options={f.name == "reportYearCompany" ||f.name == "yearCompany"  ?
                                                                companies
                                                                : f.options.map((o) => {
                                                                    var data = {
                                                                        value: o.value,
                                                                        label: o.text
                                                                    }
                                                                    return data;
                                                                })}
                                                            value={multipleValues}
                                                            isMulti
                                                            closeMenuOnSelect={false}
                                                            onChange={(a) => setMultipleValues(a)}
                                                        />}
                                                </td>
                                            </tr>
                                        }
                                    }
                                    else {
                                        if (id !== undefined && f.editable !== undefined && f.editable === false) {
                                            return <></>
                                        }
                                        else {
                                            return <tr key={i + "Tr"} id={f.name + "Tr"}>
                                                <td width="25%">
                                                    <label>
                                                        {`${f.label}`}  {f.required === undefined || f.required === true ? <span className="required">*</span> : <></>}
                                                    </label>
                                                </td>
                                                <td width="75%">
                                                    <FormInput
                                                        className="form-control"
                                                        name={f.name}
                                                        id={f.name}
                                                        disabled={f.disabled ? f.disabled : false}
                                                        checked={f.checked}
                                                        inputType={f.type} />
                                                </td>
                                            </tr>
                                        }
                                    }
                                })
                            }
                        </tbody>
                    </table>
                    <div className="d-flex flex-row mt-2">
                        <CancelButton />
                        <Button type="submit">Save</Button>
                    </div>
                </Form>
            </div>
        </Container>
    );
}

export default FormCrud;
